<template>
  <calendar
      :date-mark-definitions="dateMarkDefinitions"
      :date-marks="dateMarks"
      @changecurrent="handleMonthChange"
  ></calendar>
</template>

<script>
import Calendar from "@/components/calendar/Calendar";
import httpapi from "@/assets/javascript/httpapi";
import {FetalMovementLevel} from "@/assets/javascript/fetal-movement-utils";
import {TimeUtils} from "@/assets/javascript/time-utils";

export default {
  name: "FetalMovementCalendar",
  mixins: [httpapi],
  components: {Calendar},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      /**
       * 常量
       */
      FetalMovementLevel: FetalMovementLevel,

      /**
       * 胎动月记录
       */
      fetalMovementMonthMap: {},

      /**
       * 胎动月记录加载状态
       */
      monthLoadingMap: {},

      /**
       * 预定义日历日期标记
       */
      dateMarkDefinitions: [
        /**
         * 正常
         */
        {
          backgroundColor: '#c4e9fb',
        },
        /**
         * 胎动偏低
         */
        {
          backgroundColor: '#d2e3f1',
        },
        /**
         * 胎动偏高
         */
        {
          backgroundColor: '#e3c7ee',
        }
      ],

      dateMarks: {},
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.fetalMovementMonthMap = {};
        this.monthLoadingMap = {};
        this.loadMonth(new Date());
      },
      immediate: true,
    }
  },
  methods: {

    handleMonthChange: function (e) {
      let month = e.month;
      this.loadMonth(month);
    },

    updateDateMarks: function () {
      let dateMarks = {};

      let fetalMovementMonthMap = this.fetalMovementMonthMap;
      for (let monthStart in fetalMovementMonthMap) {
        let dateNodeList = fetalMovementMonthMap[monthStart];
        for (let dateNode of dateNodeList) {
          let dateMarkIndex = null;
          switch (dateNode.value.level) {
            case FetalMovementLevel.NORMAL:
              dateMarkIndex = 0;
              break;
            case FetalMovementLevel.LOW:
              dateMarkIndex = 1;
              break;
            case FetalMovementLevel.HIGH:
              dateMarkIndex = 2;
              break;
          }
          if (dateMarkIndex != null) {
            dateMarks[dateNode.date] = dateMarkIndex;
          }
        }
      }
      this.dateMarks = dateMarks;
    },

    loadMonth(dateInMonth) {
      if (!this.userId || !this.organizationId) return;
      let monthStart = TimeUtils.format('yyyy-MM-dd', TimeUtils.getMonthStart(dateInMonth));
      if (this.monthLoadingMap[monthStart]) return;
      this.monthLoadingMap[monthStart] = true;

      this.$reqGet({
        path: '/mt/fetal_movement/date/list/get/by_month',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          dateInMonth: monthStart,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            this.fetalMovementMonthMap[monthStart] = dateNodeList;
            this.updateDateMarks();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            delete this.monthLoadingMap[monthStart];
          })

    }

  }
}
</script>

<style scoped>

</style>