import {TimeUtils} from "./time-utils";


export const FetalMovementMode = {

    /**
     * 数胎动获得
     */
    BUILD_IN: 0,

    /**
     * 手动输入
     */
    MANUAL: 1,

    /**
     * 推算获得
     */
    CALCULATED: 2,
}

export const FetalMovementType = {

    /**
     * 1小时值
     */
    ONE_HOUR: 0,

    /**
     * 12小时值
     */
    TWELVE_HOURS: 1,

    /**
     * 24小时值
     */
    TWENTY_HOURS: 2,
}

export const FetalMovementLevel = {

    /**
     * 偏少
     */
    LOW: 0,

    /**
     * 正常
     */
    NORMAL: 1,

    /**
     * 偏高
     */
    HIGH: 2,
}

/**
 * 胎动时间段
 */
export const FetalMovementTimePeriod = {

    /**
     * 早：05:00-9:59
     */
    MORNING: 0,

    /**
     * 中：10:00-15:59
     */
    NOON: 1,

    /**
     * 晚：16:00-05:00
     */
    EVENING: 2,

}

function getFetalMovementTimePeriodByTime(time) {
    let date = TimeUtils.parseDate(time);
    let hour = date.getHours();

    if (hour >= 5 && hour < 10) {
        return FetalMovementTimePeriod.MORNING;
    } else if (hour >= 10 && hour < 16) {
        return FetalMovementTimePeriod.NOON;
    } else {
        return FetalMovementTimePeriod.EVENING;
    }
}

function getFetalMovementLevelStr(level) {
    if (level == null) return null;
    switch (level) {
        case FetalMovementLevel.LOW:
            return "偏低";
        case FetalMovementLevel.NORMAL:
            return "正常";
        case FetalMovementLevel.HIGH:
            return "偏高";
    }
}

function getFetalMovementTimePeriodStr(timePeriod) {
    switch (timePeriod) {
        case FetalMovementTimePeriod.MORNING:
            return "早";
        case FetalMovementTimePeriod.NOON:
            return "中";
        case FetalMovementTimePeriod.EVENING:
            return "晚";
    }
}

export const FetalMovementUtils = {
    getFetalMovementTimePeriodByTime,
    getFetalMovementTimePeriodStr,
    getFetalMovementLevelStr,
}