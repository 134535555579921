<template>
  <div class="fetal-movement-list-item">
    <div class="date-record layout-horizontal layout-middle" @click="handleClickDateRecord">
      <time-line
          :title="value.date"
          class="layout-self-stretch"
      ></time-line>
      <div class="margin-left font-size-small font-color-secondary">12小时推算：</div>
      <div class="font-size-medium margin-left padding-vertical-large">{{ value.value.movementOf12Hour.movement }} 次
      </div>
      <div class="layout-flexible"></div>
      <div class="font-color-placeholder padding-right-large">
        <span v-if="isExpanded"><i class="fas fa-angle-up"></i> </span>
        <span v-else><i class="fas fa-angle-down"></i> </span>
      </div>
    </div>
    <el-collapse-transition>
      <div v-if="isExpanded && fetalMovementTimeNodeList.length > 0">
        <div class="layout-horizontal layout-middle">
          <div class="layout-self-stretch line" style="margin-left: 112.5px"></div>
          <div style="margin-left: 118px;" class="time-period-bar layout-horizontal padding-left">
            <div class="time-period-item">
              <div class="icon-circle morning"><i class="fas fa-cloud-sun"></i></div>
              <div class="margin-right">早</div>
              <div v-if="morning">
                <span class="font-size-medium">{{ morning.value.movement }} 次</span>
                <span
                    v-if="morning.value.levelStr"
                    class="margin-left"
                    :class="[
                        morning.value.level === FetalMovementLevel.NORMAL ? 'font-color-success' : 'font-color-danger'
                    ]"
                >{{ morning.value.levelStr }}</span>
              </div>
            </div>
            <div class="time-period-item">
              <div class="icon-circle noon"><i class="fas fa-sun"></i></div>
              <div class="margin-right">中</div>
              <div v-if="noon">
                <span class="font-size-medium">{{ noon.value.movement }} 次</span>
                <span
                    v-if="noon.value.levelStr"
                    class="margin-left"
                    :class="[
                        noon.value.level === FetalMovementLevel.NORMAL ? 'font-color-success' : 'font-color-danger'
                    ]"
                >{{ noon.value.levelStr }}</span>
              </div>
            </div>
            <div class="time-period-item">
              <div class="icon-circle evening"><i class="fas fa-moon"></i></div>
              <div class="margin-right">晚</div>
              <div v-if="evening">
                <span class="font-size-medium">{{ evening.value.movement }} 次</span>
                <span
                    v-if="evening.value.levelStr"
                    class="margin-left"
                    :class="[
                        evening.value.level === FetalMovementLevel.NORMAL ? 'font-color-success' : 'font-color-danger'
                    ]"
                >{{ evening.value.levelStr }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
            v-for="timeNode in fetalMovementTimeNodeList"
            :key="timeNode.time"
            class="layout-horizontal layout-middle"
        >
          <time-line
              class="layout-self-stretch"
              color="#ffc4cd"
              :title="timeNode.timeStr"
          ></time-line>
          <div class="padding-left padding-vertical-large font-size-medium">
            {{ timeNode.value.movement }} 次
          </div>
          <div class="padding-left font-color-placeholder">
            <span>{{timeNode.value.timePeriodStr}}</span>
            <span v-if="timeNode.value.mode == FetalMovementMode.BUILD_IN">
              {{timeNode.value.startTimeStr}} - {{timeNode.value.endTimeStr}}
            </span>
            <span v-else-if="timeNode.value.mode == FetalMovementMode.MANUAL">
              {{timeNode.value.startTimeStr}} 手动录入
            </span>
          </div>
        </div>

      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import TimeLine from "@/pages/minitools/components/TimeLine";
import {
  FetalMovementLevel, FetalMovementMode,
  FetalMovementTimePeriod,
  FetalMovementUtils
} from "@/assets/javascript/fetal-movement-utils";

export default {
  name: "FetalMovementListItem",
  components: {TimeLine},
  mixins: [httpapi],
  props: {
    userId: Number,
    organizationId: Number,
    value: Object,
  },
  data() {
    return {

      /**
       * 常量
       */
      FetalMovementLevel: FetalMovementLevel,
      FetalMovementMode: FetalMovementMode,

      /**
       * 是否展开
       */
      isExpanded: false,

      /**
       * 当日的胎动记录列表
       */
      fetalMovementTimeNodeList: [],

      /**
       * 早
       */
      morning: null,

      /**
       * 中
       */
      noon: null,

      /**
       * 晚
       */
      evening: null,
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId, value} = this;
      return {
        userId,
        organizationId,
        value,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
      },
      immediate: true,
    },
    value: {
      handler: function (dateNode) {
        let fetalMovementTimeNodeList = dateNode.value.fetalMovementList;
        let morning = null;
        let noon = null;
        let evening = null;
        let reversedFetalMovementTimeNodeList = new Array(fetalMovementTimeNodeList.length);
        for (let n = 0; n < fetalMovementTimeNodeList.length; n++) {
          let timeNode = fetalMovementTimeNodeList[n];
          reversedFetalMovementTimeNodeList[fetalMovementTimeNodeList.length - n - 1] = timeNode;
          let timePeriod = FetalMovementUtils.getFetalMovementTimePeriodByTime("2021-11-13 " + timeNode.time);
          switch (timePeriod) {
            case FetalMovementTimePeriod.MORNING:
              if (morning == null) morning = timeNode;
              break;
            case FetalMovementTimePeriod.NOON:
              if (noon == null) noon = timeNode;
              break;
            case FetalMovementTimePeriod.EVENING:
              if (evening == null) evening = timeNode;
              break;
          }
        }

        this.morning = morning;
        this.noon = noon;
        this.evening = evening;
        this.fetalMovementTimeNodeList = reversedFetalMovementTimeNodeList;
      },
      immediate: true,
    }
  },
  methods: {
    handleClickDateRecord: function () {
      this.isExpanded = !this.isExpanded;
    }
  }

}
</script>

<style scoped>

.date-record {
  cursor: pointer;
  user-select: none;
}

.line {
  width: 2px;
  background-color: #ffc4cd;
}

.time-period-bar {
  background-color: #edf9ff;
  width: max-content;
  border-radius: 16px;
}

.time-period-item {
  padding: 8px 16px;
  width: 158px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-circle {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  border-radius: 50%;
  color: #bec0c0;
  margin-right: 8px;
}

.icon-circle.morning {
  background-color: #77af8a;
  color: white;
}

.icon-circle.noon {
  background-color: #ec6941;
  color: white;
}

.icon-circle.evening {
  background-color: #ae5da1;
  color: white;
}


</style>