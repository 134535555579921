<template>
  <div class="page-mini-tool padding layout-horizontal font-align-left">
    <div class="card layout-inflexible" style="overflow: hidden" v-if="false">
      <div class="padding padding-horizontal-large padding-top-large" style="background-color: #1ea5eb; color: white;">
        <user-info
            :user-id="userId"
            :organization-id="organizationId"
        ></user-info>
      </div>
      <fetal-movement-calendar
          class="margin-horizontal-small margin-bottom"
          :user-id="userId"
          :organization-id="organizationId"
      ></fetal-movement-calendar>
    </div>
    <div class="margin-left layout-flexible layout-vertical">
      <div class="font-size-large font-weight-bold">胎动记录</div>
      <div class="card margin-top padding-bottom-large">
        <div class="padding-horizontal-large padding-top-large font-weight-bold"><i class="fas fa-chart-bar"></i> 趋势
        </div>
        <fetal-movement-linear-chart
            :user-id="userId"
            :organization-id="organizationId"
            class="chart"
        >
        </fetal-movement-linear-chart>
      </div>
      <div class="card margin-top layout-flexible layout-vertical" style="overflow: hidden">
        <div class="padding-horizontal-large padding-top-large padding-bottom font-weight-bold">
          <i class="fas fa-list"></i> 列表
        </div>
        <fetal-movement-list
            class="layout-flexible"
            :user-id="userId"
            :organization-id="organizationId"
        ></fetal-movement-list>
      </div>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import UserInfo from "@/pages/minitools/components/UserInfo";
import FetalMovementCalendar from "@/pages/minitools/fetalmovement/components/FetalMovementCalendar";
import FetalMovementLinearChart from "@/pages/minitools/fetalmovement/components/FetalMovementLinearChart";
import FetalMovementList from "@/pages/minitools/fetalmovement/components/FetalMovementList";

export default {
  name: "MiniToolFetalMovement",
  components: {FetalMovementList, FetalMovementLinearChart, FetalMovementCalendar, UserInfo},
  mixins: [httpapi],
  data() {
    return {
      userId: null,
      organizationId: null,
    }
  },
  mounted() {
    let query = this.$route.query;
    this.userId = query.userId;
    this.organizationId = query.organizationId;
  },
  methods: {}
}
</script>

<style scoped>

.page-mini-tool {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: whitesmoke;
}

.card {
  box-shadow: 0 0 4px #c4e9fb;
}

.chart {
  height: 200px;
}


</style>