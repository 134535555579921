<template>
  <ys-infinite-scroll-view
      @loadmore="loadFetalMovementDateRecordList"
  >
    <fetal-movement-list-item
        v-for="dateNode in fetalMovementDateRecordList"
        :key="dateNode.date"
        :user-id="userId"
        :organization-id="organizationId"
        :value="dateNode"
    ></fetal-movement-list-item>
    <div class="font-align-center font-size-small font-color-placeholder padding-bottom-large">已经滑动到最后</div>
  </ys-infinite-scroll-view>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import {FetalMovementUtils} from "@/assets/javascript/fetal-movement-utils";
import {TimeUtils} from "@/assets/javascript/time-utils";
import FetalMovementListItem from "@/pages/minitools/fetalmovement/components/FetalMovementListItem";

export default {
  name: "FetalMovementList",
  mixins: [httpapi],
  components: {FetalMovementListItem, YsInfiniteScrollView},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 胎动日记录列表
       */
      fetalMovementDateRecordList: [],


    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.fetalMovementDateRecordList = [];
        this.loadFetalMovementDateRecordList();
      },
      immediate: true,
    }
  },
  methods: {

    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },

    postDateRecordLoaded: function (dateRecord) {
      dateRecord.value.movementOf12Hour.levelStr = FetalMovementUtils.getFetalMovementLevelStr(dateRecord.value.movementOf12Hour?.level);
      dateRecord.value.movementOf24Hour.levelStr = FetalMovementUtils.getFetalMovementLevelStr(dateRecord.value.movementOf24Hour?.level);

      for (let timeNode of dateRecord.value.fetalMovementList) {
        timeNode.value.timePeriod = FetalMovementUtils.getFetalMovementTimePeriodByTime(dateRecord.date + " " + timeNode.time);
        timeNode.value.timePeriodStr = FetalMovementUtils.getFetalMovementTimePeriodStr(timeNode.value.timePeriod);
        timeNode.value.startTimeStr = TimeUtils.format('HH:mm', timeNode.value.startTime);
        timeNode.value.endTimeStr = TimeUtils.format('HH:mm', timeNode.value.endTime);
        timeNode.value.levelStr = FetalMovementUtils.getFetalMovementLevelStr(timeNode.value.level);
        timeNode.timeStr = TimeUtils.format('HH:mm', dateRecord.date + " " + timeNode.time);
      }
    },

    loadFetalMovementDateRecordList: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;

      this.busyLoadingCode = this.loadingCode;

      let date = this.fetalMovementDateRecordList.length > 0 ? this.fetalMovementDateRecordList[this.fetalMovementDateRecordList.length - 1].date : null;

      this.$reqGet({
        path: '/mt/fetal_movement/date_record/list/get/before',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateRecordList = res.data;
            for (let dateRecord of dateRecordList) {
              this.postDateRecordLoaded(dateRecord);
            }
            let fetalMovementDateRecordList = this.fetalMovementDateRecordList;
            this.$appendByOrder(fetalMovementDateRecordList, dateRecordList, "date", "DESC");
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    }
  }
}
</script>

<style scoped>

</style>