<template>
  <linear-chart
      type="histogram"
      :serials="chartSerials"
      @scrolltoleft="handleScrollToLeft"
      @scrolltoright="handleScrollToRight"
  ></linear-chart>
</template>

<script>
import LinearChart from "@/components/chart/LinearChart";
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import {DateChartConverter} from "@/components/chart/DateChartConverter";
import {FetalMovementUtils} from "@/assets/javascript/fetal-movement-utils";

export default {
  name: "FetalMovementLinearChart",
  mixins: [httpapi],
  components: {LinearChart},
  props: {
    userId: Number,
    organizationId: Number
  },
  data() {
    return {
      /**
       * api同步参数
       */
      loadingCode: 1,
      busyLoadingCode: 0,

      /**
       * 胎动日列表
       */
      fetalMovementDateList: [],

      /**
       * 图形
       */
      chartSerials: [],
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.fetalMovementDateList = [];
        this.loadFetalMovementDateListBefore();
      },
      immediate: true,
    }
  },
  methods: {

    handleScrollToLeft: function () {
      console.log('scroll to left');
      this.loadFetalMovementDateListBefore();
    },

    handleScrollToRight: function () {
      console.log('scroll to right');
      this.loadFetalMovementDateListAfter();
    },

    updateChartSerials: function () {
      let serial = {
        color: '#88d6ff',
        key: 'fetal_movement',
        values: [],
        converter: new DateChartConverter(0),
      }

      let fetalMovementDateList = this.fetalMovementDateList;
      for (let dateNode of fetalMovementDateList) {
        serial.values.push({
          key: dateNode.date,
          val: dateNode.value.movement,
          valLabel: `${dateNode.value.movement}次 ${FetalMovementUtils.getFetalMovementLevelStr(dateNode.value.level)}`
        });
      }

      this.chartSerials = [serial];
    },

    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },

    loadFetalMovementDateListBefore: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;

      this.busyLoadingCode = this.loadingCode;

      let date = this.fetalMovementDateList.length > 0 ? this.fetalMovementDateList[0].date : null;

      this.$reqGet({
        path: '/mt/fetal_movement/date/list/get/before',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let fetalMovementDateList = this.fetalMovementDateList;
            this.$appendByOrder(fetalMovementDateList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadFetalMovementDateListAfter: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let date = this.fetalMovementDateList.length > 0 ? this.fetalMovementDateList[this.fetalMovementDateList.length - 1].date : null;

      this.$reqGet({
        path: '/mt/fetal_movement/date/list/get/after',
        data: {
          userId: this.userId,
          organizationId: this.organizationid,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let fetalMovementDateList = this.fetalMovementDateList;
            this.$appendByOrder(fetalMovementDateList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadFetalMovementDateListAround: function (date) {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      date = TimeUtils.format('yyyy-MM-dd', date);

      this.$reqGet({
        path: '/mt/fetal_movement/date/list/get/around',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            let fetalMovementDateList = this.fetalMovementDateList;
            this.$appendByOrder(fetalMovementDateList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadFetalMovementDateNode: function (date) {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      date = TimeUtils.format('yyyy-MM-dd', date);

      this.$reqGet({
        path: '/mt/fetal_movement/date_node/get',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNode = res.data;
            let fetalMovementDateList = this.fetalMovementDateList;
            if (dateNode) {
              this.$appendByOrder(fetalMovementDateList, [dateNode], "date", "ASC");
            } else {
              for (let n = fetalMovementDateList.length - 1; n >= 0; n--) {
                if (fetalMovementDateList[n].date === date) {
                  fetalMovementDateList.splice(n, 1);
                  break;
                }
              }
            }
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    }
  }

}
</script>

<style scoped>

</style>